import { Component, OnInit } from "@angular/core";
import { Event, Router, NavigationStart, NavigationEnd } from "@angular/router";
import { PlatformLocation } from "@angular/common";
import { SwUpdate } from "@angular/service-worker";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    
    // if (this.swUpdate.isEnabled) {
    //   this.swUpdate.available.subscribe(() => {
    //     // Swal.fire({
    //     //   title: 'New Version',
    //     //   text: 'New version available. Load New Version?!',
    //     //   icon: 'warning',
    //     //   showCancelButton: true,
    //     //   confirmButtonText: 'Yes',
    //     //   cancelButtonText: 'No'
    //     // }).then((result) => {
    //     //   if (result.value) {
    //     //     window.location.reload();
    //     //   } else if (result.dismiss === Swal.DismissReason.cancel) {
    //     //     caches.open('assets').then((cache) => {
    //     //       cache.keys().then((keys) => {
    //     //         keys.forEach((key) => {
    //     //           cache.delete(key);
    //     //         });
    //     //       });
    //     //     });
    //     //   }
    //     // })

    //   });
    // }
  }
  currentUrl: string;
  constructor(public _router: Router, location: PlatformLocation , private swUpdate : SwUpdate) {
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        // location.onPopState(() => {
        //   window.location.reload();
        // });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf("/") + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
      }
      window.scrollTo(0, 0);
    });
  }
}
