import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'todayOrDate'
})
export class TodayOrDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(date: Date | string): string {
    let now = new Date();

    if (date instanceof Date) {
      date = this.datePipe.transform(date, 'yyyy-MM-dd');
    } else {
      const parsedDate = new Date(date);
      if (!isNaN(parsedDate.getTime())) {
        date = this.datePipe.transform(parsedDate, 'yyyy-MM-dd');
      } else {
        return '';
      }
    }

    const formattedDate = this.datePipe.transform(date, 'd MMM, yyyy');
    return formattedDate === this.datePipe.transform(now, 'd MMM, yyyy') ? 'Today' : formattedDate;
  }

}
