import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { signUpresult, signUpUser } from '../models/signUp.Model';
import { signIn } from '../models/signIn.Model';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/core/service/toastr.service';
import { environment } from 'src/environments/environment';
import { resultModel } from 'src/app/doctor/account-doc/profile-doc/models';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  forgotEmail:string;

  constructor(private http: HttpClient,private router: Router,private nofify : NotificationService) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  signUp(user : signUpUser): Observable<signUpresult>{
    return this.http.post<signUpresult>(environment.apiUrl+'api/user/signUp',user)
  }
  reset() {
    this.currentUserSubject.next(null);
  }

  login(signInDetails : signIn) {
    this.reset();
    return this.http
      .post<any>(environment.apiUrl+'api/user/signIn', signInDetails)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          const updatedUser = JSON.parse(localStorage.getItem('currentUser'));
          this.currentUserSubject.next(updatedUser);
          if(this.currentUserValue.isSuccess){
            let date= new Date().getTime();
            let expirationDate = new Date(this.currentUserValue.expiryDate).getTime();
            let expiration = (expirationDate-date);
            this.autoLogout(expiration);
            this.getExpiritaionTime();
          }
          return user;
        })
      );
  }
  
  getRoles(){
    return this.http.get(environment.apiUrl+'api/roles/listroles')
  }
  getUserId() :string{
   return JSON.parse(localStorage.getItem("currentUser")).userId;
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }

getExpiritaionTime(){
  let date= new Date().getTime();
  let expirationDate = new Date(this.currentUserValue.expiryDate).getTime();
  let expiration = (expirationDate-date)*1000;
}

//auto logout implementation after session get expired 
  autoLogout(expirationDateinms :number){
    setTimeout(()=>{
this.logout();
this.nofify.showError("Session Expired , Please Login again","error")
this.router.navigate(["/authentication/signin"]);

    },expirationDateinms)
  }
  getExpiryDate(){
    this.currentUserValue.expiryDate
  }
  saveUserImage(data){
    return this.http.post(environment.apiUrl+'api/user/SaveUserImage' , data);
  }
  changePassword(data){
  return this.http.post(environment.apiUrl+'api/user/changePassword',data);
  }
  forgotPassword(data){
return this.http.post(environment.apiUrl+'api/user/forgotPassword',data);
  }
  resetPassword(data):Observable<resultModel<string>>{
    return this.http.post<resultModel<string>>(environment.apiUrl+'api/user/resetPassword',data);
      }
  getUserDetails(id:any){
 this.http.get<User>(environment.apiUrl+`api/user/getUserByIdentifier/${id}`).subscribe(res=>{
    let user = JSON.parse(localStorage.getItem('currentUser'));
        user.profileURL=res.profileURL;
        user.userId=res.userId;
        this.currentUserSubject.next(user);
        localStorage.setItem('currentUser',JSON.stringify(user));
  return res;
})
  }
  confirmEmail(userId: string,token:string):Observable<signUpresult>{
    return this.http.get<signUpresult>(environment.apiUrl+`api/user/confirmEmail?userId=${userId}&&token=${token}`)
    }
}
