import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { registerLicense } from "@syncfusion/ej2-base";
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NHaF5cWWdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWX1ccnRdQmdYWERwW0c="
);
if (environment.production) {
  enableProdMode();
}
// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .catch((err) => console.error(err));
platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
    if('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/ngsw-worker.js')
    }
    else {
      console.log("Service Workers are not supported")
      // const toast = Swal.mixin({
      //      toast: true,
      //      position: 'bottom-end',
      //      timer: 3000
      //    })
      //   toast.fire('Service workers are not supported', '', 'info')
    }
  })
  .catch(err => console.error(err));
