import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { Role } from "../models/role";
import { AuthService } from "../service/auth.service";

@Injectable({
  providedIn: "root",
})
export class DocGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    ////debugger
    if (this.authService.currentUserValue) {
      const userDetail = JSON.parse(localStorage.getItem("currentUser"));
      const userRole = this.authService.currentUserValue.roleName;
      if (userDetail && userRole !== Role.Doctor) {
        this.router.navigate(["/authentication/signin"]);
        this.authService.logout();
        return false;
      }
      return true;
    }

    this.router.navigate(["/authentication/signin"]);
    return false;
  }
}
