import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { MaterialModule } from "./material.module";
import { FeatherIconsModule } from "./components/feather-icons/feather-icons.module";
import { TruncatePipe } from "../core/pipes/shorten.pipe";
import { LoaderComponent } from "./components/loader/loader.component";
import { TodayOrDatePipe } from "../core/pipes/today-or-date.pipe";
import { CutomDirectiveDirective } from "../core/directives/cutom-directive.directive";

@NgModule({
  providers: [DatePipe],
  declarations: [
    CutomDirectiveDirective,
    TruncatePipe,
    LoaderComponent,
    TodayOrDatePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    MaterialModule,
    FeatherIconsModule,
    TruncatePipe,
    LoaderComponent,
    TodayOrDatePipe,
    CutomDirectiveDirective,
  ],
})
export class SharedModule {}
