import { AfterViewInit, Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: "[appCutomDirective]",
})
export class CutomDirectiveDirective implements AfterViewInit {
  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.renderer.setStyle(this.el.nativeElement, "margin", "20px");
  }
}

